<template>
    <v-card flat  class="my-2">
        <v-btn class="rounded-lg subprimary" color="primary" text @click="addSkill">Add skill</v-btn>
       
            <v-row class="d-flex flex-row align-center mt-3 py-0 " v-for="(skil, cle) in userskill" :key="cle">
                <v-col cols="11" class="pt-2">
                    <v-text-field v-model="skil.name.fr" :value="skil.name.fr" :label="'Skill - ' + (cle + 1)" dense
                        outlined hide-details>
                    </v-text-field>
                </v-col>
                <v-col cols="1">
                    <v-btn v-if="skil.showclose === true" elevation="0" small icon class="rounded-lg lightError"
                        @click="deleteskill(cle, skil)">
                        <v-icon small color="error">{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        
        <v-card-actions class="px-0">
            <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetSkill">
                Unsave
            </v-btn>
            <v-btn color="primary" class="mt-4" :loading="loading ? true : false" @click.prevent="saveSkills">
                Validate update
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    data() {
        return {
            loading: false,
            modeskill: [],
            userskill: [],
            icons: { mdiClose }
        }
    },
    methods: {
        loadSkills() {
            Drequest.api(`lazyloading.user-skill?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach(element => {
                        this.userskill.push({ name: element.skill.name, indent: element.skill.id, showclose: true })
                    });
                    console.log(this.userskill)
                    this.modeskill = this.userskill
                }
            }).catch((err) => { });
        },
        resetSkill() {
            alert()
            this.userskill.forEach((value, ind) => {
                this.modeskill[ind] = value
            })
        },
        deleteskill(i, value) {
            this.userskill.splice(i, 1)
        },
        addSkill() {
            this.userskill.push({
                name: {
                    fr: 'fr',
                    en: 'en'
                },
                showclose: true,
            })
        },
        // saveSkills() {
        //     this.loading = true;
        //     var bodyFormData = {F
        //         // skills: {
        //         //     user_id
        //         // }
        //     }
        //     this.loading = true;
        //     Drequest.api(`user.update?id=${this.$store.getters.getUserId}`)
        //         .data(bodyFormData)
        //         .raw((response) => {
        //             switch (response.success) {
        //                 case true:
        //                     this.loading = false;
        //                     alert(response.detail)
        //                     this.loading = false;
        //                     // this.$router.push({ name: 'user.account' });
        //                     break;
        //                 default:
        //                     alert(response.values.log)
        //                     this.loading = false;
        //                     break;
        //             }
        //         }).catch((err) => {
        //             // window.console.log(err)
        //             this.loading = false;
        //         });
        // }
    },
    computed: {
        vmodelskill() {
            return this.userskill
        },
    },
    beforeMount() {
        this.loadSkills()
    },
}
</script>