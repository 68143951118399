<template>
    <v-card flat class="my-2">
        <v-card-text>
            <v-textarea v-model="about" :value="about" hide-details dense outlined>
            </v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetForm">
                Unsave
            </v-btn>
            <v-btn color="primary" class="mt-4" :loading="loading ? true : false"
                @click.prevent="saveAboutInformations">
                Validate update
            </v-btn>
        </v-card-actions>
    </v-card>

</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    inject: {
        theme: {
            default: { isDark: false },
        },
    },
    data() {
        return {
            loading: false,
            about: '',
            oabout: ''
        }
    },
    methods: {
        resetForm() {
            this.about = this.oabout
        },
        saveAboutInformations() {
            this.loading = true;
            var bodyFormData = {
                "user": {
                    about: this.about,
                    // birthdate: this.birthdate
                }
            }
            this.loading = true;
            Drequest.api(`user.update?id=${this.$store.getters.getUserId}`)
                .data(bodyFormData)
                .raw((response) => {
                    switch (response.success) {
                        case true:
                            this.loading = false;
                            alert(response.detail)
                            this.loading = false;
                            // this.$router.push({ name: 'user.account' });
                            break;
                        default:
                            alert(response.values.log)
                            this.loading = false;
                            break;
                    }
                }).catch((err) => {
                    // window.console.log(err)
                    this.loading = false;
                });
        }
    },
    beforeMount() {
        Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
            if (response.success === true) {
                this.about = response.listEntity[0].about
                this.oabout = this.about
            }
        }).catch((err) => { });
    },
}
</script>