<template>
    <v-expansion-panels v-model="panel" multiple flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Personnals Informations</v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
        <setting-basic></setting-basic>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>About me</v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <setting-advance></setting-advance>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>My skills</v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <setting-skill></setting-skill>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>My password</v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <setting-password></setting-password>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mdiPlus, mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import SettingBasic from './settings/SettingBasic.vue'
import SettingAdvance from './settings/SettingAdvance.vue'
import settingSkill from './settings/settingSkill.vue'
import { Drequest } from '@/plugins/Drequest'
import SettingPassword from './settings/SettingPassword.vue'

export default {
  data() {
    return {
      loading: false,
      user: {},
      panel: [0, 1, 2, 3]
    }
  },
  components: {
    SettingBasic,
    SettingAdvance,
    settingSkill,
    SettingPassword
  },
  methods: {
    // updateBasicUserInfos(userForm) {
    //   this.loading = true;
    //   var bodyFormData = {
    //     'user': {
    //       firstname: this.user.firstname,
    //       lastname: this.user.lastname,
    //       email: this.user.email,
    //       phonenumber: this.user.phonenumber,
    //       password: this.user.password,
    //       username: this.user.username,
    //       address: this.user.address,
    //       // birthdate: this.user.birthdate
    //     }
    //   }
    //   this.loading = true;
    //   alert(user.address)
    //   // Drequest.api(`user.update?id=${this.$store.getters.getUserId}`)
    //   //   .data(userForm)
    //   //   .raw((response) => {
    //   //     switch (response.success) {
    //   //       case true:
    //   //         this.loading = false;
    //   //         alert(response.detail)
    //   //         this.loading = false;
    //   //         this.$store.commit("setUser", {
    //   //           id: response.user.id,
    //   //           email: response.user.email,
    //   //           firstname: response.user.firstname,
    //   //           lastname: response.user.lastname,
    //   //           phonenumber: response.user.phonenumber,
    //   //           username: response.user.username,
    //   //           // birthdate: response.user.birthdate,
    //   //           address: response.user.address,
    //   //           lastlogin: response.user.last_login,
    //   //         });
    //   //         // this.$router.push({ name: 'user.account' });
    //   //         break;
    //   //       default:
    //   //         alert(response.values.log)
    //   //         this.loading = false;
    //   //         break;
    //   //     }
    //   //   }).catch((err) => {
    //   //     window.console.log(err)
    //   //     this.loading = false;
    //   //   });
    // }
  },

  setup() {

    var icons = {
      mdiAlertOutline,
      mdiCloudUploadOutline,
      mdiPlus,
    }
    return {
      icons,
    }
  },
}
</script>
